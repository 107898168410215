.view {
  position: relative;
  padding-bottom: 80px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.history {
  background-color: #fff;
  border-radius: 4px;
  border: 0.5px solid var(--gray);
  padding: 6px 15px;
  display: flex;
  align-items: center;
}

.history span {
  margin-top: 2px;
  margin-left: 10px;
  color: var(--darkGray);
  font-weight: 500;
}

.eye {
  background-color: transparent;
  color: var(--orange);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
}

/* .eye svg {
  width: 22px;
  height: 22px;
}
 */
.warning {
  color: red;
  margin-right: 8px;
  margin-bottom: -2px;
}

.ordersButtons{
  display: flex;
  gap: 1rem
}
.containerIconViews{
  display: flex;
  justify-content: flex-end;
}

.icons{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  width: 112px;
  height: 40px;
  border: 1px solid #AEAEAE;
  border-radius: 6px;
  gap:20px;
}

.icons > *{
  cursor: pointer;
}

.containerStates{
  display: flex;
  gap:20px;
  flex-wrap: wrap;
  margin:20px 0px;
}

.state{
  width: 150px;
  height: 36px;
  border-radius: 30px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 10px;
  font-weight: 500;
  cursor: pointer;
}

.stateInactive{
 background-color: #E6E6E6;
 color:#878787;
}

.stateActive{
  background-color: var(--orange);
  color:#fff;
}

.badge{
  width: 25px;
  height: 25px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.badgeInactive{
  background-color: #ABABAB;
  color:#fff;
}
.badgeActive{
  background-color: #fff;
  color:#9E3223;
}

.containerOrders{
  display: flex;
  gap:20px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.order{
  width: 347px;
  height: 100%;
  box-shadow: 0px 2px 15px 0px #64646426;  
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
}

.header {
  border-radius: 8px;
  padding: 2px;
  background: #F5F5F5;
}
.header p {
  margin: 0px;
}


.ticketCreated{
  display: flex;
  justify-content: space-between;
}

.ticket {
  font-weight: 400;
  font-size: 15px;
  color: #444444;

}

.created{
  font-weight: 600;
  font-size: 18px;
  color: var(--orange);

}

.typeOrder {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
}


.orderDetail{
  display: flex;
  gap:10px;
  margin-top: 10px;
}


.orderItem p {
  margin: 2px;

}
.orderItemTitle{
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  color: #383838;
}

.orderItemValue{
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  color: #444444;
}
.telephoneClock{
 display: flex;
 justify-content: space-between;
}

.moreDetail{
  display: flex;
  flex-wrap: wrap;
  gap:10px;
  margin-top: 20px;
}



.labelItemBox{
  display: flex;
  flex-direction: column;
  align-items: center;

}

 .label{
  font-size: 12px;
  font-weight: 400;
  color:#383838;
  margin: 0px;

}

.itemBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 76px;
  border-radius: 8px;
  border: 1px solid #BFBFBF;
  background: #F5F5F5;
}
.total {  
  font-size: 20px;
  font-weight: 600;
  color: var(--orange);
}

@media screen AND (max-width: 720px) {
  .ordersButtons{
    flex-direction: column;
  }
}