

.loader{
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
}
/*modal */
.header{
 display: flex;
 justify-content: space-between;
 align-items: center;
 border-bottom: 1px solid #cfcfcf;
 margin-bottom: 15px;
 padding-bottom: 15px;

}

.title{
  font-family: BebasNeue;
  font-size: 700;
  font-size: 20px;
  color: --var(--darkGray)
}

.datePicker{
    width: 225px;
}

.labelFormItem{
  color:var(--darkGray);
  font-size: 12px;
  font-weight: 500;
}

.submit{
    width: 225px;
    font-weight: 700;
    font-size: 12px;
}

.colPreview{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 200px;
    height: 100px;
    border-radius: 30px;
    border:2px solid #ebebeb;
    border-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.notchLighter{
    display: flex;
    align-items: center;
    gap:10px;
    margin-bottom: 10px;
}
.notch{
    width: 50px;
    height: 10px;
    border-radius: 20px;
    border:1px solid #ebebeb;
}

.lighter{
    width: 10px;
    height: 10px;
    border-radius: 20px;
    border:1px solid #ebebeb;
}

.notification{
    display: flex;
    flex-direction: column;
    width: 175px;
    height: 50px;
    border: 2px solid #9b9b9b;
    overflow: hidden;
    white-space: nowrap;
}
.notification p {
    margin: 0px;
}