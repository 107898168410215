.card {
  background-color: white;
  box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
}

.info {
  flex: 1;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid #dcdcdc;
}

.item {
  margin-bottom: 15px;
}

.item:last-child {
  margin-bottom: 0;
}

.label {
  display: block;
  color: var(--darkGray);
  font-size: 12px;
}

.value {
  font-weight: 500;
  color: #444444;
}

.total {
  font-weight: 700;
  color: var(--orange);
  font-size: 18px;
}
