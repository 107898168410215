.loginView {
  background-color: #5e5d5d;
  width: 100vw;
  height: 100vh;
}

.background {
  background-image: url("../../../assets/backgrounds/login-background.png");
  background-size: cover;
  height: 100vh;
  position: relative;
}

.logoBackground {
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 80px;
  height: 91px;
  opacity: 0.76;
}

.form {
  background-color: #343434;
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  border-bottom: 10px solid var(--orange);
}

.subtitle {
  color: var(--gray);
  font-family: BebasNeue;
  font-weight: 700;
  font-size: 20px;
}

.info {
  margin-top: 40px;
  width: 350px;
  padding: 0;
}

.logo {
  width: 129.49px;
  height: 147.14px;
  color: white;
  margin-bottom: 20px;
}

.input {
  width: 350px;
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid var(--gray);
  margin-bottom: 10px;
  color: #c4c4c4 !important;
}

.input input {
  font-weight: 500;
  color: #c4c4c4 !important;
}

.input:hover,
.input:focus {
  border-bottom: 1px solid var(--gray) !important;
}

.submit {
  width: 350px;
  background-color: var(--orange);
  border-color: var(--orange);
  font-weight: 500;
  color: var(--black) !important;
  border-radius: 40px;
  height: 46px;
  margin-top: 40px;
}

.submit:hover,
.submit:focus {
  background-color: var(--orange) !important;
  border-color: var(--orange);
}

.icon {
  color: var(--orange);
  margin-right: 15px;
  margin-left: 10px;
}

.wave1 {
  position: absolute;
  bottom: -10px;
}

.wave2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.powered {
  position: absolute;
  bottom: 15px;
  z-index: 40;
  font-family: BebasNeue;
}

.powered a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.powered span {
  margin-right: 10px;
  display: block;
}

.powered img {
  width: 150px;
}

@media only screen and (max-width: 576px) {
  .form {
    width: 100vw;
    height: 100vh;
    padding: 150px 40px 0 40px;
  }

  .info {
    width: 100%;
  }

  .input,
  .submit {
    width: 100%;
  }

  .wave1 {
    width: 120%;
  }

  .wave2 {
    width: 120%;
  }
}
