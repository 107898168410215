.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}


.actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.action {
  cursor: pointer;
}

.divider {
  width: 1px;
  background-color: #C9C9C9;
  height: 22px;
  margin: 0 15px;

}

.history {
  background-color: #fff;
  border-radius: 4px;
  border: 0.5px solid var(--gray);
  padding: 6px 15px;
  display: flex;
  align-items: center;
}

.history span {
  margin-top: 2px;
  margin-left: 10px;
  color: var(--darkGray);
  font-weight: 500;
}

.headerModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 15px;
}

.titleModal {
  color: var(--darkGray);
  font-family: BebasNeue;
  font-weight: 700;
  font-size: 20px;
}



.submit {
  width: 250px;
  font-weight: 700;
  font-size: 13px;
}


.labelFormItem {
  font-weight: 500;
  color: var(--darkGray);
  font-size: 12px;
}