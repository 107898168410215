.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.back {
  display: flex;
  align-items: center;
  color: var(--orange);
  font-family: BebasNeue;
  font-weight: 700;
  font-size: 26px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.back:hover {
  color: var(--orange);
}

.backButton {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--orange);
  color: white;
  margin-right: 15px;
}

.orders {
  margin-top: 30px;
}

.search {
  width: 200px;
  margin-right: 15px;
}

.input button {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-color: #c9c9c9;
}

.input input {
  color: #7e7e7e !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-color: #c9c9c9;
}

.picker {
  border-color: #c9c9c9;
  border-radius: 5px !important;
  margin-right: 15px;
}

.preparationIcon {
  width: 20px;
  height: 20px;
}

.eye {
  background-color: transparent;
  color: var(--orange);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
}

.eye svg {
  width: 22px;
  height: 22px;
}

.activeOrder{
  background-color: var(--orange);
  color: white;
}