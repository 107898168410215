.view{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.containerForm{
    border: 1px solid var(--gray);
    width: 100%;
    max-width: 600px;
    padding: 15px;
}

.title {
    color: var(--darkGray);
    font-family: BebasNeue;
    font-weight: 700;
    font-size: 20px;
  }
  
  .label {
    font-weight: 500;
    color: var(--darkGray);
    font-size: 12px;
  }

  .input,
.input :global(.ant-select-selector) {
  background-color: #fbfbfb !important;
  border-color: #d2d2d2 !important;
  border-radius: 4px !important;
}

.submit {
    width: 250px;
    font-weight: 700;
    font-size: 13px;
  }
  .tableLabel{
    display: flex;
    gap:10px;
    margin:20px 0px ;
  }

 .loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap:10px;
}

.actions svg{
  cursor: pointer;
}
