.view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.edit,
.delete {
  cursor: pointer;
  margin-top: 3px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  width: 1px;
  background-color: #c9c9c9;
  height: 22px;
  margin: 0 15px;
}

.watch {
  color: var(--orange);
}

.eye {
  width: 20px;
  height: 20px;
}

.antForm{
  display: grid;
  grid-template-columns: repeat(3,200px);
  gap:50px;
  width: 100%;
}

.label {
  font-weight: 500;
  color: var(--darkGray);
  font-size: 12px;
}

.input,
.input :global(.ant-select-selector) {
  background-color: #fbfbfb !important;
  border-color: #d2d2d2 !important;
  border-radius: 4px !important;
}

.itemColumn {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.submit {
  width: 175px;
  font-weight: 700;
  font-size: 13px;
}