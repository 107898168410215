.view {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  
  .description {
    width: 200px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .input {
    background-color: #fbfbfb;
    border-color: #d2d2d2;
    border-radius: 4px;
    width: 300px;
  }
  
  .buttons{
    display: flex;
    justify-content: space-between;
  }
  .actions{
    display: flex;
    justify-content: flex-end;
    gap:25px;
  }
  
  .menu {
    cursor: pointer;
  }
  
  .mainButton{
    width: 200px;
    font-weight: 500;
  }
  
  
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .title {
    color: var(--darkGray);
    font-family: BebasNeue;
    font-weight: 700;
    font-size: 20px;
  }
  
  .label{
    font-weight: 500;
    color: var(--darkGray);
    font-size: 12px;
  }
  
  .input,
  .input :global(.ant-select-selector){
    background-color: #fbfbfb !important;
    border-color: #d2d2d2 !important ;
    border-radius: 4px !important;
  }
  
  
  .itemColumn {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .itemSubmit{
    margin-bottom: 0px;
    text-align: right;
    margin-top: 20px;
  }
  
  .submit {
    width: 250px;
    font-weight: 700;
    font-size: 13px;
  }