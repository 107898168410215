

.containerLabelSwitches{
    display: flex;
    justify-content: space-between;
}

.switches{
    display: flex;
    gap:10px;
}
.formEditor{
    margin-top: 30px;
    position: relative;
}

.permissions{
    position: absolute;
    right: 0px;
    top: -80px;
    display: flex;
    gap:20px;
    
}

.permissions > div {
  text-align: center;
  margin-left: 40px;
}

.permissions span {
  display: block;
}


.avatarUploader.ant-upload-select-picture-card{
  width: 100%;
  height: 250px;
  border-radius: 20px;
  background-color: var(--darkGray);
  border-color: var(--grey);
  overflow: hidden;
}

.placeholderImg svg {
  width: 50px;
  height: 50px;
  color: var(--gray);
}
.photo{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.subActions{
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap:20px;
}

.btn {
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 180px;
  border-radius: 10px;
  font-weight: 700;
}

.btnMap{
  background-color: #FDF8E5;
  color: #F9A41A;
}

.btnAvailable{
  background-color: #F1F5FE;
  color:#3357E3;
}

.btnLock{
  background-color: #FEF2F4;
  color:#ED243D;
}

.actions{
  display: flex;
  gap:30px;
  justify-content: flex-end;
}



.title {
    color: var(--darkGray);
    font-family: BebasNeue;
    font-weight: 700;
    font-size: 20px;
  }
  
  .label {
    font-weight: 500;
    color: var(--darkGray);
    font-size: 12px;
  }
  

  .input,
.input :global(.ant-select-selector) {
  background-color: #fbfbfb !important;
  border-color: #d2d2d2 !important;
  border-radius: 4px !important;
}

.submit {
    width: 180px;
    font-weight: 700;
    font-size: 13px;
    border-radius: 0px;
    justify-content: center;
  }

  .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 15px;
}

.title {
  color: var(--darkGray);
  font-family: BebasNeue;
  font-weight: 700;
  font-size: 20px;
}

.loaderWrap{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
