.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.back {
    display: flex;
    align-items: center;
    color: var(--orange);
    font-family: BebasNeue;
    font-weight: 700;
    font-size: 26px;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.back:hover {
    color: var(--orange);
}

.backButton {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--orange);
    color: white;
    margin-right: 15px;
}
.title-box {
    font-size: 1.1em;
    color: black;
    padding-top: 0.6em;
}

.badge-driver .ant-space-item {
    width: 10em;
}

.buttonCounter {
    background-color: white;
    border-radius: 8px;
    height: auto;
    display: inline-flex;
    flex-flow: row;
    line-height: 2em;
    margin-right: 1em;
}