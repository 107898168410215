.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 15px;
}

.title {
  color: var(--darkGray);
  font-family: BebasNeue;
  font-weight: 700;
  font-size: 20px;
}

.label {
  font-weight: 500;
  color: var(--darkGray);
  font-size: 12px;
}

.input,
.input :global(.ant-select-selector) {
  background-color: #fbfbfb !important;
  border-color: #d2d2d2 !important;
  border-radius: 4px !important;
}

.itemColumn {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.itemSubmit {
  margin-bottom: 0;
  text-align: right;
  margin-top: 20px;
}

.submit {
  width: 250px;
  font-weight: 700;
  font-size: 13px;
}

.swtichWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.swtichWrap .label {
  margin-right: 10px;
}

.noMargin {
  margin-bottom: 0;
}

.uploadImage {
  border: 1px solid #d2d2d2;
  background-color: #fbfbfb;
  color: #383838;
  font-weight: 500;
  width: 100%;
  height: 35px;
  border-radius: 4px;
}

.center {
  text-align: center;
  display: block;
}

.preview {
  background-color: #f3f3f3;
  height: 120px;
  width: 120px;
  border-radius: 100%;
  margin: 0 auto 20px auto;

  background-size: cover;
  background-position: center;
}

.password {
  margin-right: 20px;
}
