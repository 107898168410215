.denied {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.06px;
    color:#F13C02;
}

.approve{
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.06px;
    color:#0EC315;
}

.pending{
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.06px;
    color: var(--darkGray)
}

.loader{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.actions {
    display: flex;
    gap:25px;
}