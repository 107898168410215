
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    border-bottom: 1px solid #cfcfcf;
    padding-bottom: 15px;
  }
  
  .title {
    color: var(--darkGray);
    font-family: BebasNeue;
    font-weight: 700;
    font-size: 20px;
  }
  
  
  
  .submit {
    width: 250px;
    font-weight: 700;
    font-size: 13px;
  }
  
  .timePicker{
    width: 200px;
  }
  .labelFormItem {
    font-weight: 500;
    color: var(--darkGray);
    font-size: 12px;
  }