.view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.infoWrap {
  background-color: white;
  box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);
  border-radius: 6px;
  margin-bottom: 40px;
  padding: 20px 0;
}

.infoCol {
  padding: 0 20px;
}

.infoCol span {
  display: block;
  margin-bottom: 15px;
}

.infoCol span:last-child {
  margin-bottom: 0;
}

.actions {
  display: flex;
  align-items: baseline;
}

.switchWrap {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.eye {
  background-color: transparent;
  color: var(--orange);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
}

.eye svg {
  width: 22px;
  height: 22px;
}

.titleTable{
  color: var(--darkGray);
  font-family: BebasNeue;
  font-weight: 700;
  font-size: 20px;
}

.divider{
  height: 20px;
  border-width: 1px;
}

.actions svg{
  cursor: pointer;
}

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 15px;
}

.title{
  color: var(--darkGray);
  font-family: BebasNeue;
  font-weight: 700;
  font-size: 20px;
}

.label{
  font-weight: 500;
  color: var(--darkGray);
  font-size: 12px;
}

.input,
.input :global(.ant-select-selector){
  background-color: #fbfbfb !important;
  border-color: #d2d2d2 !important ;
  border-radius: 4px !important;
}


.itemColumn {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.itemSubmit{
  margin-bottom: 0px;
  text-align: right;
  margin-top: 20px;
}

.submit {
  width: 250px;
  font-weight: 700;
  font-size: 13px;
}