.header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
    border-bottom: 1px solid #cfcfcf;
    padding-bottom: 15px;
    gap: 30%;
}

.title {
    color: var(--darkGray);
    font-family: BebasNeue;
    font-weight: 700;
    font-size: 20px;
}

.label {
    font-weight: 500;
    color: var(--darkGray);
    font-size: 12px;
}

.item {
    display: flex;
    align-items: baseline;
    gap: 20px;
}

.result {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
}

.active {
    color: var(--orange);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
}

.finished {
    color: var(--darkGray);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
}

.divider {
    width: 100%;
    height: 2px;
    gap: 0px;
    border: 1px;
    border: 1px solid #CFCFCF;
}

.input,
.input :global(.ant-select-selector) {
    background-color: #fbfbfb !important;
    border-color: #d2d2d2 !important;
    border-radius: 4px !important;
    width: 100%;
}


.starts {
    display: flex;
    gap: 5px;
}
.containerElipse{
    display: flex;
    flex-direction: column;
    gap:5px;
    align-items: center;
}
.elipse {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    border-radius: 100px;
    border: 1px solid var(--orange);
}

.elipse p {
    font-family: Roboto;
    font-size: 36px;
    font-weight: 500;
    color: var(--orange);
    margin: 0px;
}

