
.cardContent{
   width: 800px;
}

/** Summary **/

.gridCard{
    width: 100%;
    max-width: 800px;
}

.heading{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 56px;
    font-weight: 400;
    color: var(--orange);
    margin: 0px;
}

.heading p {
    position: relative;
    font-size: 25px;
    margin: 0px;
    top: 10px;
   
}


.subheading{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color:#585858;
}

/** OrdersArea **/

.select{
    width: 300px !important;
}


.containerTable{
    background-color: #FFF;
    border-radius: 6px;
    box-shadow: 0px 2px 15px 0px #64646426;
    display: grid;
    grid-template-columns: repeat(4,1fr);
}

.containerTableArea{
    background-color: #FFF;
    border-radius: 6px;
    box-shadow: 0px 2px 15px 0px #64646426;
    display: grid;
    grid-template-columns: repeat(3,1fr);
}

.headerItemTable{
    border-bottom: 1px solid #D6D6D6;
    border-right: 1px solid #D6D6D6;
    font-size: 12px;
    font-weight: 400;
    color: #383838;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bodyCellTable{
    padding-top: 10px;
    border-right: 1px solid #D6D6D6;
    font-size: 16px;
    font-weight: 400;
    color: #444444;
    text-align: center;
}

.lastHeaderItemTable{
    border-right: 0px;
}

.bodyCellTable:last-of-type{
    border-right:0px;
}

/** CustomerTracking **/

.itemDatePicker{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
}

.totalOverview{
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.antCard{
    width: 250px;
}

.antCard div{
    display: flex;
    flex-direction: column;
    gap:10px;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.number{
    font-size: 56px;
    font-weight: 400;
    color: #585858;
    margin: 0px;
}

.label{
    font-size: 14px;
    font-weight: 500;
    color:var(--orange);
    margin: 0px;
}

.amount{
   font-size: 68px;
   font-weight: 400;
   color: #585858;
   margin: 0px;
}

.percentSuccess{
    font-size: 34px;
    font-weight: 400;
    color:#01B652;
    margin: 0px;
}

.percentFail{
    font-size: 34px;
    font-weight: 400;
    color:#FC4850;
    margin: 0px;
}

.indicator{
    font-size: 18px;
    font-weight: 500;
    color: #585858;
    margin: 0px;
}

.amountPercent{
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap:10px;
}

.containerIndicators{
    display: flex;
    box-shadow: 0px 2px 15px 0px #64646426;
    background-color: #FFFFFF;
    height: 225px;
}

.buttonListClient{
    border: 1px solid #585858;
}

.indicatorOverview {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 200px;
    gap:20px
}

.divider{
    height: 100%;
}

/** Average Times **/

.legend{
    font-size: 16px;
    font-weight: 400;
    color:#444444;    
}

.charts{
    display: flex;
    justify-content: center;
    width: 100%;
}

.chartContent{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.chartLegends{
    display: flex;
    gap:25px;
}
.chartLegend{
    display: flex;
    gap:10px;
}

.chartLegendBox{
    width: 22px;
    height: 22px;
    border: 1px;
    border: 1px solid #808080;
}

.chartLegendBoxPreparation{
    background-color: #585858;
}

.chartLegendBoxOnTheWay{
    background-color: #FF671D;
}

/** TotalOverview **/
.containerTotalOverviewCard{
    width: 800px;
    max-width: 100%;
}
.legendTotalOverview{
   font-size: 24px;
   font-weight: 500;
   color: #444444;
}

/** OrdersSales **/

/** OrdersPerHour **/

.formOrders{
    display: flex;
    justify-content: flex-end;
}