.header {
  display: flex;
  justify-content: space-between;
}
.title {
    color: var(--darkGray);
    font-family: BebasNeue;
    font-weight: 700;
    font-size: 20px;
  }
.input,
.input :global(.ant-select-selector) {
  background-color: #fbfbfb !important;
  border-color: #d2d2d2 !important;
  border-radius: 4px !important;
}

.labelFormItem {
  font-weight: 500;
  color: var(--darkGray);
  font-size: 12px;
}

.labelFormItemBackup {
  font-weight: 500;
  color: var(--orange);
  font-size: 12px;
}

.scheduledActive {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.scheduleTime {
  width: 559px;
  margin-top: 20px;
  border-radius: 8px;
  border: 1px solid #cbcbcb;
  padding: 15px;
}

.headerScheduleTime {
  display: flex;
  justify-content: space-between;
}
.labelHeaderScheduleTime {
  font-size: 12px;
  font-weight: 600;
}

.actionHeaderScheduleTime {
  display: flex;
  gap: 10px;
}

.week {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 10px;
}

.day {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f5f8ff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.activeDay {
  background-color: #9e3223;
  color: #fff;
}

.currentDay {
  background-color: var(--orange);
  color: #fff;
}

.selectedDayContainer {
  display: flex;
  margin-top: 20px;
  gap: 10px;
}

.selectedDay {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 10px;
}

.divider {
  display: flex;
  width: 425px;
  height: 1px;
  border: 1px solid #adadad;
}

.saveDateTimeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #626262;
  width: 157px;
  height: 40px;
  padding: 4px;
  gap: 4px;
  border-radius: 50px;
  border: 1px solid var(--orange);
}
.deleteIcon{
    cursor: pointer;
}
.containerDateEnd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.selectedDate {
  display: flex;
  flex-direction: column;
}
.datePicker{
  width: 250px;    
}
.containerSubmitButton {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.submitButton {
  width: 200px;
  font-weight: 700;
  font-size: 14px;
}
