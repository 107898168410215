.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  border-bottom: 1px solid #bebebe;
  padding-bottom: 10px;
}

.title {
  font-family: BebasNeue;
  font-weight: 700;
  color: var(--darkGray);
  font-size: 20px;
}

.body {
  text-align: center;
  color: var(--darkGray);
  margin: 40px 0;
}

.body p {
  display: block;
  margin-bottom: 30px;
}

.action {
  display: flex;
  justify-content: center;
}

.submit {
  font-weight: 500;
  font-size: 12px;
  width: 300px;
}

.input,
.input .ant-select-selector {
  background-color: #fbfbfb;
  border-color: #d2d2d2;
  border-radius: 4px;
}
