.layout{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap:10px;
}

.content{
    width: 100%;
    padding: 10px;
}

/** DateFilters **/

.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:20px;
    margin-bottom: 25px;
}

.tabs{
  display: flex;
  justify-content: space-between;
  gap:15px;
}


.tab{
    font-size: 16px;
    font-weight: 500;
    color: #7E7E7E;
    cursor: pointer;
}

.activeTab{
    color: var(--orange);
    border-bottom: 2px solid var(--orange);
}


.pickers{
    display: flex;
    gap:15px;
    align-items: center;
}

/** NavItems **/

.cardsContainer{
    display: flex;
    flex-direction: column;
    width: 250px;
    gap: 25px;
    position: relative;
    bottom: 65px;
 }
 
 .card{
     font-size: 14px;
     font-weight: 500; 
     text-align: center;
 }
 
 .activeCard{
     background-color: var(--orange);
     color: #FFF;
 }