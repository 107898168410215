.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header h1 {
  color: var(--orange);
  font-family: BebasNeue;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 0;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.edit,
.delete {
  cursor: pointer;
  margin-top: 3px;
}
.containerButton{
  display: flex;
  gap: 10px;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  width: 1px;
  background-color: #c9c9c9;
  height: 22px;
  margin: 0 15px;
}

.banner {
  width: 250px;
  height: 100px;
  background-position: center center;
  background-size: cover;
  border-radius: 4px;
}

.tablesButtons{
  display: flex;
  gap:10px;
  margin-bottom: 10px;
}
